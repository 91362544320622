import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  CardMedia,
  Container,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import { createUser } from "../firebase";
import logoSvg from "../imgs/logo.png";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';


const Register = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [point, setPoint] = useState("");
  const [profileImageUrl, setprofileImageUrl] = useState(
    "https://firebasestorage.googleapis.com/v0/b/notiline-vtoisn.appspot.com/o/user.png?alt=media&token=470b8374-53a9-40c6-8013-68a466566fef"
  );
  const [displayName, setDisplayName] = useState("");
  const [error, setError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const register = async () => {
    try {
      if (password !== confirmPassword) {
        throw new Error("Las contraseñas no coinciden.");
      }

      // Validar que todos los campos requeridos estén completos
      if (!email || !password || !confirmPassword || !phone || !point || !displayName) {
        throw new Error("Por favor, complete todos los campos.");
      }

      await createUser(
        email,
        password,
        displayName,
        phone,
        point,
        profileImageUrl
      );

      // Limpiar el formulario después de un registro exitoso
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setPhone("");
      setPoint("");
      setprofileImageUrl(
        "https://firebasestorage.googleapis.com/v0/b/intellichallenge-7d25c.appspot.com/o/user.png?alt=media&token=6dfbd95a-8f4f-4357-ab4c-3f38db121f5b"
      );
      setError("");
      navigate("/home");
    } catch (error) {
      setError(error.message);
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  const styles = {
    media: {
      marginLeft: "auto",
      marginRight: "auto",
      width: "170px",
      height: "auto",
      paddingTop: "8%", // 16:9,
      marginTop: "40px",
      marginBottom: "30px",
    },
    register: {
      fontWeight: "300",
      fontSize: "18px",
      marginTop: "-10px",
    },
  };

  return (
    <Container maxWidth="xs" sx={{ mt: 1 }}>
      <CardMedia
        component="img"
        image={logoSvg} // Utilizar la variable que contiene la imagen SVG
        title="Notiline"
        style={styles.media} // Especificar estilos si es necesario
      />
      <Typography
        variant="h5"
        style={styles.register}
        component="h1"
        gutterBottom
        textAlign="center"
      >
        Registrarse
      </Typography>
      {error && (
        <Alert severity="error" sx={{ my: 2 }}>
          {error}
        </Alert>
      )}
      <Box component="form">
        <Container maxWidth="sm">
          <TextField
            autoFocus
            label="Correo Electrónico"
            fullWidth
            margin="normal"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Contraseña"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={password}
            required
            onChange={(e) => setPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            label="Repetir Contraseña"
            type={showPassword ? "text" : "password"}
            fullWidth
            margin="normal"
            value={confirmPassword}
            required
            onChange={(e) => setConfirmPassword(e.target.value)}InputProps={{
              endAdornment: (
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
          />
          <TextField
            label="Nombre de participante"
            fullWidth
            margin="normal"
            value={displayName}
            required
            onChange={(e) => setDisplayName(e.target.value)}
          />
          <TextField
            label="Número de Teléfono"
            fullWidth
            margin="normal"
            value={phone}
            required
            onChange={(e) => setPhone(e.target.value)}
          />
          <TextField
            label="Punto (Localidad)"
            fullWidth
            margin="normal"
            value={point}
            required
            onChange={(e) => setPoint(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={register}
            sx={{ mt: 3 }}
            fullWidth
          >
            Registrarse
          </Button>
        </Container>
        <Box textAlign="center" sx={{ p: 5, mt: 2 }}>
          ¿Ya tienes cuenta? <Link href="/login">Iniciar sesión</Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Register;
