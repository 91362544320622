import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Avatar, List, ListItem, ListItemAvatar, ListItemText, Divider, CircularProgress } from '@mui/material';
import { getSession, isLoggedIn } from '../session';
import { getFirestore, collection, addDoc, query, orderBy, onSnapshot, Timestamp, limit } from 'firebase/firestore';

const db = getFirestore();

const Comments = () => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [currentUser, setCurrentUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const loggedIn = await isLoggedIn();
        setIsAuthenticated(loggedIn);
        if (loggedIn) {
          const session = await getSession();
          setCurrentUser(session);
        }
      } catch (error) {
        console.error("Error checking auth status:", error);
        setError("Failed to check authentication status");
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    const commentsRef = collection(db, 'comments');
    const q = query(
      commentsRef,
      orderBy('timestamp', 'desc'),
      limit(50) // Limitamos a 50 comentarios para evitar cargar demasiados a la vez
    );

    const unsubscribe = onSnapshot(q, 
      (snapshot) => {
        const fetchedComments = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setComments(fetchedComments);
        setIsLoading(false);
      },
      (error) => {
        console.error("Error fetching comments:", error);
        setError("Failed to fetch comments");
        setIsLoading(false);
      }
    );

    return () => unsubscribe();
  }, []);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (newComment.trim() && isAuthenticated) {
      try {
        const commentsRef = collection(db, 'comments');
        await addDoc(commentsRef, {
          author: currentUser.email,
          content: newComment,
          timestamp: Timestamp.now(),
          userId: currentUser.uid
        });
        setNewComment('');
      } catch (error) {
        console.error("Error adding comment:", error);
        setError("Failed to add comment");
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <Box sx={{ maxWidth: 600, margin: 'auto', mt: 4, mb: 10 }}>
      <Typography variant="h5" gutterBottom>
        Comentarios
      </Typography>
      {comments.length > 0 ? (
        <List>
          {comments.map((comment) => (
            <React.Fragment key={comment.id}>
              <ListItem alignItems="flex-start">
                <ListItemAvatar>
                  <Avatar>{comment.author[0].toUpperCase()}</Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={comment.author}
                  secondary={
                    <>
                      <Typography component="span" variant="body2" color="text.primary">
                        {comment.content}
                      </Typography>
                      <Typography variant="caption" display="block">
                        {comment.timestamp.toDate().toLocaleString()}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider variant="inset" component="li" />
            </React.Fragment>
          ))}
        </List>
      ) : (
        <Typography variant="body2">Todavía no hay comentarios</Typography>
      )}
      {isAuthenticated ? (
        <Box component="form" onSubmit={handleCommentSubmit} sx={{ mt: 2 }}>
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Añadir un comentario..."
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button type="submit" variant="contained" color="primary">
            Enviar comentario
          </Button>
        </Box>
      ) : (
        <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
          Por favor inicia sesion para comentar.
        </Typography>
      )}
    </Box>
  );
};

export default Comments;