import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Paper,
  Toolbar,
  IconButton,
  AppBar,
  Avatar,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import PolicyIcon from "@mui/icons-material/Policy";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CircularProgress from "@mui/material/CircularProgress";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import Atras from "./atras";
import { auth, uploadImage, updateUserProfile } from "../firebase";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { endSession, isLoggedIn } from "../session";
import { useNavigate } from "react-router-dom";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import logo from "../imgs/logo.png";

const Perfil = () => {
  const [displayName, setDisplayName] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null); // Estado para la URL de la imagen de perfil

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const { uid } = user;
          const db = getFirestore();
          const userDocRef = doc(db, "users", uid);

          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const userName = userData.displayName || "Usuario";
            const userEmail = userData.email || "";
            setEmail(userEmail);
            setDisplayName(userName);
            if (userData.profileImageUrl) {
              setProfileImageUrl(userData.profileImageUrl);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setDisplayName("");
        setProfileImageUrl(null);
      }
    });

    return unsubscribe;
  }, []);



  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    try {
      // Activar el indicador de progreso
      setLoading(true);

      // Subir la imagen al almacenamiento y obtener la URL de descarga
      const imageUrl = await uploadImage(file);

      // Obtener el usuario actual
      const user = auth.currentUser;
      if (user) {
        const uid = user.uid;

        // Actualizar la URL de la imagen de perfil en la base de datos
        await updateUserProfile(uid, { profileImageUrl: imageUrl });

        // Actualizar el estado con la nueva URL de la imagen de perfil
        setProfileImageUrl(imageUrl);
      }

      // Desactivar el indicador de progreso después de completar la carga
      setLoading(false);
    } catch (error) {
      console.error("Error uploading image:", error);
      // En caso de error, desactivar el indicador de progreso
      setLoading(false);
    }
  };

  let navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const onLogout = () => {
    endSession();
    navigate("/login");
  };

  const styles = {
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
      backgroundColor: "lightgrey",
      height: "100vh",
    },
    user: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "flex-start",
      width: "100%",
      height: "90px",
      marginTop: "10px",
      color: "black",
    },
    progress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: "-20px",
      marginLeft: "-20px",
      color: "white",
    },
    uploadButton: {
      marginTop: "20px",
      color: "white",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "slateblue" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          {profileImageUrl && (
            <Avatar
              sx={{
                marginLeft: 2,
                padding: "0px",
                border: "2.5px solid white",
              }}
              alt="Perfil"
              src={profileImageUrl}
              style={styles.avatar}
            />
          )}
          <div style={{ position: "relative" }}>
            <input
              type="file"
              accept="image/*"
              onClick={(event) => (event.target.value = null)}
              onChange={handleImageUpload}
              style={{ display: "none" }}
              id="upload-button"
            />
            <IconButton
              variant="contained"
              component="span"
              sx={styles.uploadButton}
              onClick={() => document.getElementById("upload-button").click()}
              color="black"
            >
              <AddPhotoAlternateIcon />
              {loading && (
                <CircularProgress
                  size={40}
                  thickness={4}
                  style={styles.progress}
                />
              )}
            </IconButton>
          </div>
          <Container style={styles.user}>
            <Typography variant="h5" color="white">
              {displayName}
            </Typography>
            <Typography
              variant="h6"
              sx={{ fontSize: "12px", color: "white" }}
              gutterBottom
            >
              {email}
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 17, mb: 14 }}>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "96%",
              height: "60px",
              padding: "10px",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            <Typography variant="h6">
              <IconButton href="/edit" color="inherit">
                <SettingsIcon />
              </IconButton>
              Editar Perfil
            </Typography>
          </Paper>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "96%",
              height: "60px",
              padding: "10px",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            {/* Contenido de la sección de términos de servicio */}
            <Typography variant="h6">
              <IconButton href="/terms" color="inherit">
                <AutoStoriesIcon />
              </IconButton>
              Términos y Condiciones
            </Typography>
            {/* Agrega más contenido según sea necesario */}
          </Paper>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "96%",
              height: "60px",
              padding: "10px",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            {/* Contenido de la sección de términos de servicio */}
            <Typography variant="h6">
              <IconButton href="/privacyPolicy" color="inherit">
                <PolicyIcon />
              </IconButton>
              Política y Privacidad
            </Typography>
            {/* Agrega más contenido según sea necesario */}
          </Paper>
        </Container>
        <Container
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Paper
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              width: "96%",
              height: "60px",
              padding: "10px",
              textAlign: "left",
              marginTop: "20px",
            }}
          >
            {/* Contenido de la sección de términos de servicio */}
            <Typography variant="h6" color={"red"}>
              <IconButton onClick={onLogout} color="inherit">
                <ExitToAppIcon />
              </IconButton>
              Cerrar sesión
            </Typography>
            {/* Agrega más contenido según sea necesario */}
          </Paper>
        </Container>
      </Container>
      <FloatingWhatsApp
        phoneNumber="+542323680787"
        placeholder="Escribe un mensaje..."
        statusMessage="Responde en menos de 10 minutos"
        accountName="IntelliChallenge"
        avatar={logo}
        chatMessage="Hola!¿Cómo estás?¿En que puedo ayudarte?😃"
      />
    </Container>
  );
};

export default Perfil;
