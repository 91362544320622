/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Avatar,
  AppBar,
  Toolbar,
  Link,
  Button,
  Modal,
  Box,
  IconButton,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { auth, saveTokenToFirestore, messaging } from "../firebase";
import { getToken } from "firebase/messaging";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import { isLoggedIn } from "../session";
import { useNavigate } from "react-router-dom";
import IdeaEvaluation from "./ideaEvaluation";
import Comentarios from "./comentarios";

const Home = () => {
  const [displayName, setDisplayName] = useState("");
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [openModal, setOpenModal] = useState(true);
  const navigate = useNavigate();
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  const requestNotificationPermission = async (messaging) => {
    try {
      const permission = await Notification.requestPermission();
      if (permission === "granted") {
        const token = await getToken(messaging, {
          vapidKey:
            "BCRyW5s55PL8nMiFlqO3AJgOkxCH-oDhzzSyfE5XoGgV2XXwFTWrAXtX3WiHLEluAU6H4Gs5jt0U1Sg5flOKV0Q",
        });
        console.log("FCM token:", token);
  
        if (token) {
          await saveTokenToFirestore(token);
        } else {
          console.error("No se pudo obtener el token FCM");
        }
      } else {
        console.error("Permiso de notificaciones denegado");
      }
    } catch (error) {
      console.error("Error al obtener el token FCM:", error);
    }
  };

  useEffect(() => {
    const checkAuthAndRequestPermission = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);

      if (loggedIn) {
        await requestNotificationPermission(messaging);
      }
    };

    checkAuthAndRequestPermission();
  }, []);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const { uid } = user;
          const db = getFirestore();
          const userDocRef = doc(db, "users", uid);

          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            const userName = userData.displayName || "Usuario";
            setDisplayName(userName);
            if (userData.profileImageUrl) {
              setProfileImageUrl(userData.profileImageUrl);
            }
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      } else {
        setDisplayName("");
        setProfileImageUrl(null);
      }
    });

    return unsubscribe;
  }, []);


  const styles = {
    media: {
      width: "70px",
      marginTop: "15px",
      marginBottom: "15px",
    },
    avatar: {
      marginLeft: 2,
      padding: "0px",
      border: "2.5px solid white",
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 300,
      bgcolor: 'background.paper',
      borderRadius: '25px',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
      p: 4,
    },
    closeButton: {
      position: 'absolute',
      right: 8,
      top: 8,
      color: 'grey.500',
    },
    title: {
      color: '#1976d2',
      fontWeight: 'bold',
      mb: 2,
    },
    subtitle: {
      color: 'text.secondary',
      mb: 3,
    },
    paymentButton: {
      mt: 3,
      mb: 2,
      width: '100%',
      bgcolor: '#4caf50',
      '&:hover': {
        bgcolor: '#45a049',
      },
    },
    infoText: {
      mt: 2,
      fontSize: '0.9rem',
      color: 'text.secondary',
      fontStyle: 'italic',
    },
  };

  return (
    <Container>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100vw",
          bgcolor: "slateblue",
        }}
      >
        <Link
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          href="/profile"
        >
          <Toolbar>
            {profileImageUrl && (
              <Avatar sx={styles.avatar} alt="Perfil" src={profileImageUrl} />
            )}
            <Container style={styles.user}>
              <Typography variant="subtitle1" color="white">
                Hola, {displayName}
              </Typography>
            </Container>
          </Toolbar>
        </Link>
      </AppBar>
      <Container style={styles.container}>
        <IdeaEvaluation />
        <Comentarios/>
      </Container>
    </Container>
  );
};

export default Home;