import React, { useEffect, useState } from "react";
import "./index.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CardMedia } from "@mui/material";
import Login from "./routes/login";
import Register from "./routes/register";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Edit from "./routes/edit";
import Terms from "./routes/terms";
import Policy from "./routes/privacyPolicy";
import Atras from "./routes/atras";
import HeaderPerfil from "./routes/HeaderPerfil";
import Comentarios from "./routes/comentarios";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo.png";

const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  return (
    <Router>
      {loading ? (
        <Loading />
      ) : (
        <Routes>
          <Route path="/" element={user ? <Home handleLogout={handleLogout} /> : <Login />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/home" element={<Home handleLogout={handleLogout} />} />
          <Route path="/atras" element={<Atras />} />
          <Route path="/HeaderPerfil" element={<HeaderPerfil />} />
          <Route path="/comentarios" element={<Comentarios />} />
          <Route path="/edit" element={<Edit />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacyPolicy" element={<Policy />} />
        </Routes>
      )}
    </Router>
  );
};

export default App;