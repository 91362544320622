import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Container,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  styled,
  Paper,
  Alert,
  Snackbar,
  Avatar,
} from "@mui/material";
import {
  getFirestore,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  getDoc,
  doc,
  serverTimestamp,
  setDoc,
  updateDoc,
  Timestamp,
  onSnapshot,
} from "firebase/firestore";
import { auth } from "../firebase";

const ProfileBox = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const ProfileAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(7),
  height: theme.spacing(7),
  marginRight: theme.spacing(2),
}));

const theme = createTheme({
  palette: {
    primary: {
      main: "#1976d2",
    },
    secondary: {
      main: "#dc004e",
    },
    background: {
      default: "#f5f5f5",
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  margin: theme.spacing(2, 0),
  backgroundColor: "#ffffff",
  boxShadow: "0 3px 5px 2px rgba(0, 0, 0, .1)",
}));

const PrizeTypography = styled(Typography)(({ theme }) => ({
  backgroundColor: "white",
  border: "1px solid black",
  padding: theme.spacing(1, 2),
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
}));

const WarningAlert = styled(Alert)(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
}));

const IdeaEvaluation = () => {
  const [userIdea, setUserIdea] = useState("");
  const [submittedIdea, setSubmittedIdea] = useState(null);
  const [loading, setLoading] = useState(false);
  const [winningIdea, setWinningIdea] = useState(null);
  const [explanation, setExplanation] = useState("");
  const [timeUntilEvaluation, setTimeUntilEvaluation] = useState(null);
  const [nextEvaluationDate, setNextEvaluationDate] = useState(null);
  const [participantCount, setParticipantCount] = useState(0);
  const [winningIdeaDate, setWinningIdeaDate] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "info",
  });

  const db = useMemo(() => getFirestore(), []);

  const showSnackbar = (message, severity = "info") => {
    setSnackbar({ open: true, message, severity });
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const checkUserIdea = useCallback(async () => {
    const user = auth.currentUser;
    if (user) {
      const ideasRef = collection(db, "ideas");
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const q = query(
        ideasRef,
        where("userId", "==", user.uid),
        where("createdAt", ">=", today)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        setSubmittedIdea(querySnapshot.docs[0].data());
      }
    }
  }, [db]);

  const formatWinningDate = useCallback((date) => {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return "hoy";
    } else if (date.toDateString() === yesterday.toDateString()) {
      return "ayer";
    } else {
      return date.toLocaleDateString();
    }
  }, []);

  const fetchPreviousWinner = useCallback(async () => {
    try {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      yesterday.setHours(0, 0, 0, 0);

      const q = query(
        collection(db, "ideas"),
        where("createdAt", ">=", yesterday),
        where("isWinner", "==", true)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const winningIdeaDoc = querySnapshot.docs[0];
        const winningIdeaData = winningIdeaDoc.data();
        setWinningIdea({
          id: winningIdeaDoc.id,
          ...winningIdeaData,
        });
        setWinningIdeaDate(winningIdeaData.createdAt.toDate());

        const explanationRef = doc(db, "explanations", winningIdeaDoc.id);
        const explanationDoc = await getDoc(explanationRef);
        if (explanationDoc.exists()) {
          setExplanation(explanationDoc.data().text);
        }
      }
    } catch (error) {
      console.error("Error fetching previous winner:", error);
      showSnackbar("Error fetching previous winner", "error");
    }
  }, [db]);

  const fetchNextEvaluationTime = useCallback(async () => {
    try {
      const evaluationSettingsDocRef = doc(db, "settings", "evaluation");
      const evaluationSettingsDoc = await getDoc(evaluationSettingsDocRef);

      if (evaluationSettingsDoc.exists()) {
        const nextEvaluationTimestamp =
          evaluationSettingsDoc.data().nextEvaluationTime;
        const nextEvalDate = nextEvaluationTimestamp.toDate();
        setNextEvaluationDate(nextEvalDate);
      } else {
        console.error("Evaluation settings document not found.");
        const defaultEvaluationTime = new Date();
        defaultEvaluationTime.setHours(19, 30, 0, 0);
        if (defaultEvaluationTime < new Date()) {
          defaultEvaluationTime.setDate(defaultEvaluationTime.getDate() + 1);
        }
        await setDoc(evaluationSettingsDocRef, {
          nextEvaluationTime: Timestamp.fromDate(defaultEvaluationTime),
        });
        setNextEvaluationDate(defaultEvaluationTime);
      }
    } catch (error) {
      console.error("Error fetching next evaluation time:", error);
      showSnackbar("Error fetching next evaluation time", "error");
    }
  }, [db]);

  const fetchParticipantCount = useCallback(async () => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const q = query(collection(db, "ideas"), where("createdAt", ">=", today));
      const querySnapshot = await getDocs(q);
      setParticipantCount(querySnapshot.size);
    } catch (error) {
      console.error("Error fetching participant count:", error);
      showSnackbar("Error fetching participant count", "error");
    }
  }, [db]);

  const submitIdea = async () => {
    if (!userIdea.trim()) return;
    setLoading(true);
    try {
      const user = auth.currentUser;
      await addDoc(collection(db, "ideas"), {
        userId: user.uid,
        userName: user.displayName,
        description: userIdea,
        createdAt: serverTimestamp(),
        profileImageUrl: user.photoURL || null, // Add this line
      });
      setSubmittedIdea({ description: userIdea });
      setUserIdea("");
      await checkUserIdea();
      await fetchParticipantCount();
      showSnackbar("Idea submitted successfully!", "success");
    } catch (error) {
      console.error("Error submitting idea:", error);
      showSnackbar("Error submitting idea", "error");
    }
    setLoading(false);
  };

  const evaluateIdeas = useCallback(async () => {
    setLoading(true);
    try {
      const ideasRef = collection(db, "ideas");
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const q = query(ideasRef, where("createdAt", ">=", today));

      const querySnapshot = await getDocs(q);
      const ideas = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const { winningIdea, explanation } = simulateEvaluation(ideas);

      if (winningIdea) {
        setWinningIdea(winningIdea);
        setExplanation(explanation);

        const winningIdeaRef = doc(db, "ideas", winningIdea.id);
        await updateDoc(winningIdeaRef, { isWinner: true });

        const explanationRef = doc(db, "explanations", winningIdea.id);
        await setDoc(explanationRef, { text: explanation });

        const nextEvalTime = new Date();
        nextEvalTime.setDate(nextEvalTime.getDate() + 1);
        nextEvalTime.setHours(19, 30, 0, 0);
        const evaluationSettingsDocRef = doc(db, "settings", "evaluation");
        await setDoc(evaluationSettingsDocRef, {
          nextEvaluationTime: Timestamp.fromDate(nextEvalTime),
        });

        setNextEvaluationDate(nextEvalTime);
        showSnackbar("Evaluation completed successfully!", "success");
      }
    } catch (error) {
      console.error("Error evaluating ideas:", error);
      showSnackbar("Error evaluating ideas", "error");
    } finally {
      setLoading(false);
    }
  }, [db]);

  const simulateEvaluation = (ideas) => {
    if (ideas.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * ideas.length);
    const winningIdea = ideas[randomIndex];

    const explanationTemplates = [
      `La idea "${winningIdea.description}" de ${winningIdea.userName} ha sido seleccionada como la mejor debido a su originalidad y potencial impacto. Esta propuesta destaca por su enfoque innovador para abordar un problema común de manera creativa.`,
      `${winningIdea.userName} ha presentado una idea sobresaliente con "${winningIdea.description}". Esta propuesta fue elegida por su viabilidad y el claro beneficio que podría aportar a nuestra comunidad/empresa.`,
      `"${winningIdea.description}" se destaca entre las demás por su visión a largo plazo. ${winningIdea.userName} ha demostrado una comprensión profunda de las necesidades futuras y ha propuesto una solución que podría tener un impacto duradero.`,
      `La idea de ${winningIdea.userName}, "${winningIdea.description}", ha captado nuestra atención por su simplicidad y eficacia. A veces, las mejores soluciones son las más sencillas, y esta propuesta tiene el potencial de resolver un problema importante de manera elegante.`,
      `${winningIdea.userName} ha presentado una idea verdaderamente inspiradora con "${winningIdea.description}". Esta propuesta no solo aborda un problema actual, sino que también abre nuevas posibilidades para futuras innovaciones en este campo.`,
    ];

    const randomExplanation =
      explanationTemplates[
        Math.floor(Math.random() * explanationTemplates.length)
      ];

    return { winningIdea, explanation: randomExplanation };
  };

  const formatTime = (ms) => {
    if (ms < 0) return "00:00:00";
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / 1000 / 60) % 60);
    const hours = Math.floor((ms / (1000 * 60 * 60)) % 24);
    return [hours, minutes, seconds]
      .map((v) => v.toString().padStart(2, "0"))
      .join(":");
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkUserIdea();
      await fetchPreviousWinner();
      await fetchNextEvaluationTime();
      await fetchParticipantCount();
    };
    fetchData();
  }, [
    checkUserIdea,
    fetchPreviousWinner,
    fetchNextEvaluationTime,
    fetchParticipantCount,
  ]);

  useEffect(() => {
    let timer;
    if (nextEvaluationDate) {
      timer = setInterval(() => {
        const now = new Date();
        const timeDiff = nextEvaluationDate - now;
        setTimeUntilEvaluation(timeDiff);

        if (timeDiff <= 0) {
          clearInterval(timer);
          evaluateIdeas();
        }
      }, 1000);
    }

    return () => {
      if (timer) clearInterval(timer);
    };
  }, [nextEvaluationDate, evaluateIdeas]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(
        collection(db, "ideas"),
        where("createdAt", ">=", new Date().setHours(0, 0, 0, 0))
      ),
      (snapshot) => {
        setParticipantCount(snapshot.size);
      },
      (error) => {
        console.error("Error listening to real-time updates:", error);
        showSnackbar("Error updating participant count", "error");
      }
    );

    return () => unsubscribe();
  }, [db]);

  const styles = {
    title: {
      color: '#1976d2',
      fontWeight: 'bold',
      mb: 2,
    },
    subtitle: {
      color: 'text.secondary',
      mb: 3,
    },
    paymentButton: {
      mt: 3,
      mb: 2,
      width: "100%",
      bgcolor: "#4caf50",
      "&:hover": {
        bgcolor: "#45a049",
      },
    },
    infoText: {
      mt: 2,
      fontSize: "0.9rem",
      color: "text.secondary",
      fontStyle: "italic",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="md" sx={{ mt: 7, py: 3 }}>
        <PrizeTypography variant="body1" align="center">
        <Typography variant="h5" component="h2" sx={styles.title}>
              ¡Participa y gana!
            </Typography>
            <Typography sx={styles.subtitle}>
              Envía $1000 para participar (opcional)
            </Typography>
          <Button
            variant="contained"
            color="primary"
            href="https://mpago.la/32wJttW"
            sx={styles.paymentButton}
          >
            Enviar $1000
          </Button>
          <Typography sx={styles.infoText}>
            El total recaudado del día será enviado al ganador a las 19:30
            cuando se realiza el sorteo.
          </Typography>
        </PrizeTypography>
        <StyledPaper>
          <Typography variant="h4" gutterBottom align="center">
            Envío de ideas para participar
          </Typography>
          <Typography variant="h6" align="center" gutterBottom>
            Participantes hoy: {participantCount}
          </Typography>
          <WarningAlert severity="warning" style={{ textAlign: "justify" }}>
            Advertencia: Si se detecta que un participante intenta aprovecharse
            injustamente del sistema, se seleccionará un nuevo ganador. Juega
            limpio y da lo mejor de ti con tu idea.
          </WarningAlert>
          {submittedIdea ? (
            <Typography variant="body1" align="center" sx={{ my: 2 }}>
              Ya has enviado una idea hoy:{" "}
              <strong>{submittedIdea.description}</strong>
            </Typography>
          ) : (
            <Box component="form" noValidate autoComplete="off" sx={{ mt: 2 }}>
              <TextField
                fullWidth
                variant="outlined"
                label="Tu idea"
                rows={3}
                multiline
                value={userIdea}
                onChange={(e) => setUserIdea(e.target.value)}
                disabled={loading}
                sx={{ mb: 2 }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={submitIdea}
                disabled={loading || !userIdea.trim()}
                sx={{ py: 1.5 }}
              >
                {loading ? <CircularProgress size={24} /> : "Enviar idea!"}
              </Button>
            </Box>
          )}
        </StyledPaper>
        <StyledPaper>
          <Typography variant="h6" align="center" gutterBottom>
            Tiempo hasta la evaluación: {formatTime(timeUntilEvaluation)}
          </Typography>
        </StyledPaper>
        {winningIdea && (
          <StyledPaper>
            <Typography variant="h5" gutterBottom align="center">
              Idea ganadora de{" "}
              {winningIdeaDate ? formatWinningDate(winningIdeaDate) : "hoy"}
            </Typography>
            <ProfileBox>
              <ProfileAvatar
                src={winningIdea.profileImageUrl || "/default-avatar.png"}
                alt={winningIdea.userName}
              />
              <Typography variant="h6">{winningIdea.userName}</Typography>
            </ProfileBox>
            <Typography variant="body1" paragraph>
              <strong>Idea:</strong> {winningIdea.description}
            </Typography>
            <Typography variant="h6" gutterBottom>
              Explicación:
            </Typography>
            <Typography variant="body1" style={{ textAlign: "justify" }}>
              {explanation}
            </Typography>
          </StyledPaper>
        )}
      </Container>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message={snackbar.message}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default IdeaEvaluation;
